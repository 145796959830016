<template>
  <div>
    <div class="row justify-content-center align-items-md-center">
      <div class="col-md-12">
        <div class="card full-height bg-ligth">
          <div class="card-header">
            <h3 class="mb-0">{{ lista_campos == '' ? 'Criar' : 'Editar' }} Pedido de recebimento</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <form action="">
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="col-md-12">Selecione Usuario :*</label>
                      <treeselect
                          v-if="lista_campos == ''"
                          :multiple="false"
                          required
                          :async="true"
                          v-model="form.pedido.usuario_id"
                          :load-options="param => searchUsuarios(param)"
                      />
                      <input
                          v-else
                          disabled
                          type="text"
                          class="form-control"
                          :value="selectedUser"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="col-md-12">Descrição:*</label>
                      <input
                          required
                          type="text"
                          class="form-control"
                          v-model="form.pedido.descricao"
                          placeholder="Digite o nome..."
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <label class="col-md-12">Emissao:*</label>
                      <input
                          required
                          type="date"
                          class="form-control"
                          v-model="form.pedido.emissao"
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="col-md-12">Vencimento:*</label>
                      <input
                          required
                          type="date"
                          class="form-control"
                          v-model="form.pedido.data_validade"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="col-md-12"
                      >Selecione Forma de Pagamento :*</label
                      >
                      <select
                          class="form-control"
                          v-model="form.pedido.finan_forma_pagamento_id"
                          name=""
                          id=""
                      >
                        <option
                            v-for="(lista, index) in lista_finan_modo_pagamentos"
                            :key="index"
                            :value="lista.id"
                        >
                          {{ lista.nome }}
                        </option>
                      </select>
                    </div>
                  </div>

               
                </form>
              </div>

              <div class="col-md-6 border-left">
                <div class="d-flex justify-content-end w-100">
                  <button @click="showModalItens()" class="btn btn-info">
                    Adicionar Itens
                  </button>
                </div>
                <div class="table-responsive mt-5">
                  <b-table
                      class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                      :items="lista_itens_produto"
                      show-empty
                      empty-text="Nenhum registro encontrado!"
                      :fields="['nome_produto','quantidade', 'valor_unitario', 'acao']"
                  >
                    <template #head(acao)=""><span></span></template>
                    <template #cell(valor_unitario)="{item}">
                      {{ item.valor_unitario | formatMoney }}
                    </template>
                    <template #cell(acao)="{index}">
                      <div class="text-right w-100">
                        <a href="#" @click.prevent="deleteItemProduto(index)"
                           class="btn btn-icon btn-light btn-sm">
                          <span class="svg-icon svg-icon-md svg-icon-danger">
                            <inline-svg src="media/svg/icons/General/Trash.svg"></inline-svg>
                          </span>
                        </a>
                      </div>
                    </template>

                    <template #custom-foot="{items}">
                      <div class="ml-2" v-if="items.length">
                        <strong>Total:</strong> {{ valorTotal | formatMoney }}
                        <br>
                        <strong>Desconto:</strong> {{ totalDesconto | formatMoney }}
                      </div>
                    </template>

                  </b-table>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <hr>
                <div class="form-group mt-10">
                  <div class="col-md-12 text-center">
                    <button
                        @click="confirm(lista_campos == '' ? 'cria' : 'edita')"
                        class="btn btn-primary"
                        :disabled="verif"
                    >
                      Salvar
                      <b-spinner
                          v-show="verif"
                          small
                          variant="dark"
                          type="grow"
                          label="Spinning"
                      ></b-spinner>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-modal
            class="bg-primary"
            ref="modalitens"
            size="md"
            title="Cricao dos Itens do Pedido"
        >

          <form class="mt-4">
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Selecione o Contrato :*</label>
                <select
                    class="form-control"
                    v-model="itens.produto_id"
                    name=""
                >
                  <option
                      v-for="(lista, index) in lista_produtos"
                      :key="index"
                      :value="lista.id"
                  >
                    {{ lista.nome }} | {{ lista.valor | formatMoney }}
                  </option>
                </select>
              </div>
            </div>

           

            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">valor :*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="itens.valor_desconto"
                    placeholder="Digite o valor do desconto..."
                />
              </div>
            </div>
          </form>

          <template v-slot:modal-footer="{}">
            <div class="d-flex justify-content-between w-100">
              <b-button size="sm" variant="secondary" @click.prevent="closeModalItens">
                Cerrar
              </b-button>
              <b-button @click.prevent="salvar_itens()" variant="info" size="sm">
                Adicionar
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>

import {fireAlert} from "@/core/mixins/alertMessageMixin";
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import moneyFormat from "@/core/mixins/moneyFormat";

export default {
  mixins: [fireAlert, moneyFormat],
  data() {
    return {
      form: {
        pedido: {
          usuario_id: null,
          nome: "",
          descricao: "",
          status: 1,
          emissao: "",
          data_validade: "",
          valor_total: "",
          finan_forma_pagamento_id: null,
        },
        conta_id: null,
        itens: [],
      },
      itens: {
        valor_unitario: null,
        nome_produto: null,
        produto_id: null,
        quantidade: null,
        valor_desconto: "",
      },
      verif: false,
      selectedUser: null,
    };
  },
  mounted() {
   
    this.listar_requerimentos()
        .finally(() => {
         
          this.preenxerCampos()
        });
    let actionTitle = this.lista_campos == '' ? 'Criar' : 'Editar'
    this.$store.dispatch(SET_BREADCRUMB, [{title: `${actionTitle} Pedido de Recebimento`}]);

  },
  computed: {
    totalDesconto() {
      return this.lista_itens_produto.reduce((curr, item) => {
        return Number(curr) + Number(item.valor_desconto)
      }, 0)
    },
    valorTotal() {
      return this.lista_itens_produto.reduce((curr, item) => {
        return curr + (item.valor_unitario * item.quantidade)
      }, 0)
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
    lista_finan_moedas() {
      return this.$store.state.financeiro.lista_finan_moedas;
    },

    lista_finan_modo_pagamentos() {
      return this.$store.state.financeiro.lista_finan_modo_pagamentos;
    },

    lista_produtos() {
      return this.$store.state.produto.lista_produtos;
    },
    lista_itens_produto() {
      return this.$store.state.financeiro.lista_itens_produto;
    },
    lista_usuarios() {
      return this.$store.state.configFuncionario.lista_usuarios;
    },
  },
  methods: {
    searchUsuarios({searchQuery, callback}) {
      ApiService.post(`usuario/buscar/`, {tipo: 'nome', texto: searchQuery})
          .then(({data}) => {
            let options = data.map(usuario => ({id: usuario.id, label: usuario.nome}))
            callback(null, options)
          })
    },
    deleteItemProduto(index) {
      if (!this.lista_itens_produto[index].id) {
        this.lista_itens_produto.splice(index, 1)
        return
      }
      this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação irá remover o produto da sua lista`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("MudarPreloader", true)
            this.$store.dispatch('delete_produto_pedido_recebimento', index)
                .finally(() => this.$store.dispatch("MudarPreloader", false))
          }
        },
      });
    },
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + tipo + ` uma Pedido Recebimento no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      this.form.itens = this.lista_itens_produto;
      await this.$store.dispatch("create_finan_pedido_recebimento", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'finanPedidoRecebimento'
      });
    },
    async update() {
      this.verif = true;
      this.form.itens = this.lista_itens_produto;
      await this.$store.dispatch("update_finan_pedido_recebimento", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'finanPedidoRecebimento'
      });
    },
    preenxerCampos() {
      if (this.lista_campos == "") {
        return
      }
     
      this.$store.dispatch('show_finan_pedido_recebimento', this.lista_campos.id)
          .finally(() => this.$store.dispatch("MudarPreloader", false))

      this.selectedUser = this.lista_campos.usuario
      this.form = {
        pedido: {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          descricao: this.lista_campos.descricao,
          usuario_id: this.lista_campos.usuario_id,
          data_validade: this.lista_campos.data_validade,
          finan_forma_pagamento_id: this.lista_campos.finan_forma_pagamento_id,
          emissao: this.lista_campos.emissao,
        },
      };
    },
    async listar_requerimentos() {
      this.$store.dispatch('limpar_lista_produtos')
      return Promise.all([
        this.$store.dispatch("financeiro/listar_finan_caixa"),
        this.$store.dispatch("financeiro/listar_finan_modo_pagamento"),
        this.$store.dispatch("financeiro/listar_finan_moeda"),
     
        this.$store.dispatch("configUsuarios/listar_usuarios"),
        this.$store.dispatch("produto/listar_produto"),
      ])
    },
    showModalItens() {
      this.$refs["modalitens"].show();
      this.itens.produto_id = "";
      this.itens.nome_produto = "";
      this.itens.valor_unitario = "";
      this.itens.quantidade = "";
      this.itens.valor_desconto = "";
    },
    closeModalItens() {
      this.$refs["modalitens"].hide();
    },
    async salvar_itens() {
      this.itens.valor_unitario = this.lista_produtos.find((item) => {
        return item.id === this.itens.produto_id
      }).valor
      await this.$store.dispatch("guardar_itens", this.itens);
      this.closeModalItens();
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>